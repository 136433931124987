import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { AuthService } from '../../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { RegisterStudentComponent } from '../../register-student/register-student.component';
import { UserService } from '../../services/user.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Constant that holds the API for retrieving configuration */
  readonly CONFIG_API = 'api/config/';

  /** Variable that determines if the sidebar should be toggled */
  public toggle = true;

  /** Variable that determines if the user is logged in */
  loggedIn = false;
  /** Variable that holds the type of the current user */
  currentUserType = '';
  /** Variable that determines if the current url is the homepage */
  isHome = false;
  isProgram = false;
  isUniversity = false;
  /** Variable that determines if the current url is the email confirmation page */
  isConfirmation = false;
  /** Variable that holds the name of the user that is currently logged in */
  username = '';
  tipoUSer = '';
  /** Variable that determines if menu can be displayed */
  showMenu = false;

  schoolId: any;

  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;
  /** Subscription to monitor the logged in user's username */
  usernameSubscription: Subscription;
  userTypeSubscription: Subscription;
  userSubscription: Subscription;
  /** Subscription used to monitor the router events */
  routerSubscription: Subscription;
  canLeavePage: boolean = true;

  /** Current used language */
  currentLanguage;
  urlMatches: any;
  isPremiumUser: boolean;
  selectedUserType: any;
  /** Variable that holds the configuration */
  config = {
    id: '',
    tagList: [],
    maxProgramsWithOutLogin: 0,
    usePaymentCost: 0,
    breakingNewsSpanish: '',
    breakingNewsEnglish: '',
  };
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  /** Text of banner (breaking news) */
  newsTexts: any = '';
  public openMenu = false;
  isColegio: boolean;
  isRecruitment: boolean;
  isEstudiante: boolean;
  showDropdown: boolean;
  user: any;
  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.schoolId = this.getSchoolId();

    window.addEventListener('beforeunload', this.handleBeforeUnload);



    this.route.queryParams.subscribe((params) => {
      const isUserLogOut = params['logout'];


      if (isUserLogOut === 'true') {
        this.logOut();
      }
    });
    this.urlMatches = this.isTargetUrl();

    this.subscribeToUserChanges();
    this.authService.isPremiumUser$.subscribe((result) => (this.isPremiumUser = result));
    if (isPlatformBrowser(this.platformId)) {
      this.setLanguage();
      this.authService.isPremiumUser$.subscribe((result) => (this.isPremiumUser = result));
      this.logInSubscription = this.authService.loggedIn.subscribe((logInStatus) => {
        if (logInStatus === true) {
          this.currentUserType = this.user?.tipo;
        } else {
          this.currentUserType = '';
        }
        this.loggedIn = logInStatus;
      });
      this.usernameSubscription = this.authService.userName.subscribe((username) => {
        this.username = username;
      });
    }
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = event.urlAfterRedirects.split('/');
        const last = route[route.length - 1];
        if (event.urlAfterRedirects === '/home') {
          this.isHome = true;
        } else if (event.urlAfterRedirects.includes('/university')) {
          this.isUniversity = true;
        } else if (
          event.urlAfterRedirects.includes('/program') &&
          !(event.urlAfterRedirects.includes('/program') && event.urlAfterRedirects.includes('/search'))
        ) {
          this.isProgram = true;
        } else {
          this.isHome = false;
          this.isProgram = false;
          this.isUniversity = false;
          if (last === 'confirmation') {
            this.isConfirmation = true;
          }
        }
      }
    });


  }

  private subscribeToUserChanges(): void {
    this.userSubscription = this.userService.currentUser$.subscribe((userData) => {
      if (userData) {
        this.user = userData;
        this.checkProfileType(userData);
      }
    });

  }

  //redirectToSchoolsService(): void {
  //this.schoolId = localStorage.getItem('schoolId');
  //const token = localStorage.getItem('tokenSchool');
  //this.navigationService.navigateTo({ path: '/public-home' });
  //if (this.schoolId && token) {
  // const redirectURL = `${environment.SCHOOLS_URL}?ref=${token}&schoolId=${schoolId}`;
  //const redirectURL = "/public-home"
  //window.location.href = redirectURL;

  //}


  handleBeforeUnload(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = ''; // Esto muestra el mensaje de advertencia
  }

  redirectToSchoolsService(): void {
    // Remover el evento beforeunload antes de redirigir
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    this.schoolId = localStorage.getItem('schoolId');
    const token = localStorage.getItem('tokenSchool');
    this.navigationService.navigateTo({ path: '/school-profile/' + this.schoolId });
  }

  onFormChange(): void {
    this.canLeavePage = false; // Evitar abandonar si hay cambios no guardados
  }

  getSchoolId(): string {
    if (this.user?._id) {
      this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).subscribe((response: any) => {
        this.schoolId = response._id;
        localStorage.setItem('schoolId', this.schoolId);


      });
    }
    return this.schoolId;
  }
  isTargetUrl() {
    const targetUrl = 'https://www.u360education.com/web/english-test';
    return window.location.href === targetUrl;
  }

  getConfig(language): void {
    this.apiService.get({ api: this.CONFIG_API }).subscribe((response) => {
      this.config = response[0];
      if (language == 'es') {
        this.newsTexts = this.config.breakingNewsSpanish;
      } else {
        this.newsTexts = this.config.breakingNewsEnglish;
      }
    });
  }

  ngDoCheck(): void {
    if (localStorage.getItem('tipoUser')) {
      this.tipoUSer = JSON.parse(localStorage.getItem('tipoUser'));
    }
  }

  ngOnDestroy(): void {
    if (this.logInSubscription !== null && this.logInSubscription !== undefined) {
      this.logInSubscription.unsubscribe();
    }
    if (this.routerSubscription !== null && this.routerSubscription !== undefined) {
      this.routerSubscription.unsubscribe();
    }
    if (this.usernameSubscription !== null && this.usernameSubscription !== undefined) {
      this.usernameSubscription.unsubscribe();
    }

    window.removeEventListener('beforeunload', () => { });

  }

  /**
   * Displays and hides the side navbar
   */
  public toggleNav(): void {
    this.toggle = !this.toggle;
  }

  /**
   * Handles the click on the login button
   */
  onLoginLogoutClick(): void {
    if (this.loggedIn === false) {
      this.authService.sitioMensaje = '';
      setTimeout(() => {
        this.dialog.open(AuthComponent, {
          data: {
            authAction: 'login',
            selectedUserType: this.selectedUserType,
          },
          maxWidth: '95vw',
        });
      }, 0);
    } else {
    }

  }

  logOut() {
    this.authService.logout();
    this.authService.setLoggedIn({ status: false });
    this.navigationService.navigateTo({ path: '/public-home' });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tipoUser');
    localStorage.removeItem('schoolId');
    localStorage.removeItem('username');
    localStorage.removeItem('loggedIn');
  }
  /** Handles the click on the signup button */
  onSignUpButtonClick(): void {
    this.authService.sitioMensaje = '';
    this.dialog.open(AuthComponent, { data: { authAction: 'signup' }, maxWidth: '95vw' });
  }

  /**
   * Handles the click on the logo --> Redirect to the homepage
   */
  onLogoClick(): void {
    this.navigationService.navigateTo({ path: '/public-home' });
  }
  /**
   * Handles the click on the go to favourites button
   */
  onFavouritesClick(): void {
    if (this.loggedIn === false) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      this.navigationService.navigateTo({ path: 'favourites' });
    }
  }

  /**
   * Handles the click on the go to webinars button
   */
  onWebinarClick(): void {
    if (this.loggedIn === false) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      this.navigationService.navigateTo({ path: 'webinar' });
    }
  }

  /** Handles the navigation to profile event */
  onGoToProfile(): void {
    const user = localStorage.getItem('user');
    this.navigationService.navigateTo({ path: '/profile' });
  }

  /**
   * Changes language of the app with translator service
   */
  useLanguage(language: string): void {
    this.currentLanguage = language;
    localStorage.setItem('lang', this.currentLanguage);
    this.translate.use(language);
    this.getConfig(language);
    window.location.reload();
  }

  /**
   * Sets language depending on localStorage when the app launches
   */
  public setLanguage(): void {
    const saved = localStorage.getItem('lang');
    if (saved && saved.length === 2) {
      this.currentLanguage = saved;
      this.translate.use(saved);
      this.getConfig(saved);
    } else {
      this.currentLanguage = 'es';
      localStorage.setItem('lang', this.currentLanguage);
    }
  }

  /**
   * Handles the click on the register student button
   */
  onRegisterStudent(): void {
    this.dialog.open(RegisterStudentComponent);
  }

  showMenuAction() {
    this.showMenu = !this.showMenu;
  }
  onDisplayMenu() {
    this.openMenu = !this.openMenu;
  }

  onFullAccess($event: MouseEvent): void {
    $event.stopPropagation();
    this.authService.verifyPhoneUser(true);
  }

  checkProfileType(user): void {
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isEstudiante = user?.tipo === 'Estudiante';
  }
}
