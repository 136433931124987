import { CanActivate } from '@angular/router';
import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AlertService } from './alert.service';
import { NavigationService } from './navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthComponent } from '../auth/auth.component';
import { SetPhoneComponent } from '../shared/set-phone/set-phone.component';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /** Constant that holds the base url for making backend requests */
  public baseUrl = environment.BASE_URL;

  /** Constant that hols the API route for login */
  readonly LOGIN_API = 'users/authenticate';

  /** Constant that holds the API route for register */
  readonly REGISTER_API = 'users/registrar-estudiante';

  /** Constant that hols the API route for update user */
  readonly USER_PUT_PHONE = 'api/user/';

  /** Variable that holds the http headers */
  private headers: HttpHeaders;

  /**Variable para mostrar u ocultar el texto del test de ingles */
  public sitioMensaje = '';

  /** Variable that holds the login status */
  loggedIn = new BehaviorSubject(false);

  /** Variable that holds the login status */
  socialLoggedIn = new BehaviorSubject(false);

  /** Variable that holds the current user's name */
  userName = new BehaviorSubject('');
  userType = new BehaviorSubject('');
  /** Variable that holds the intended url when the user tries to log in but is unauthorized */
  private intendedUrl: string;
  cookieValue: string;
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject({});

  private isPremiumUser: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
    private socialAuthService: SocialAuthService,
    @Optional() private dialogRef: MatDialogRef<AuthComponent>,
    @Optional() private dialogSetPhoneRef: MatDialogRef<SetPhoneComponent>,
    private dialog: MatDialog,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private apiService: ApiService
  ) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');

    const loggedInState = localStorage.getItem('loggedIn');
    if (loggedInState) {
      this.loggedIn.next(JSON.parse(loggedInState));
    }

    const username = localStorage.getItem('username');
    if (username) {
      this.userName.next(username);
    }else{
      this.userName.next('');
    }
  }

  get isPremiumUser$(): Observable<boolean> {
    return this.isPremiumUser.asObservable();
  }

  setIsPremiumUser(value: boolean): void {
    this.isPremiumUser.next(value);
  }

  setData(data) {
    /*let newObj = JSON.parse(localStorage.getItem('myData'));
    newObj = data;*/
    // this.cookieService.delete('frmUSer');
    const jsonData = JSON.stringify(data);
    this.cookieService.set('frmUSer', jsonData);
    /*let localNew = localStorage.setItem('myData', jsonData);
    let newObj2 = JSON.parse(localStorage.getItem('myData'));
    ;*/
  }

  getData() {
    const user = this.cookieService.get('frmUSer');
    return user;
  }

  removeData(key) {
    localStorage.removeItem(key);
    return false;
  }

  public verifyPhoneUser(avoidValidations?: boolean): void {
    const user = JSON.parse(localStorage.getItem('user'));
    if ((user && (!user.telefono || (!user.procedenciaComoLead && user.tipoRedSocial))) || avoidValidations) {
      this.dialog.closeAll();
      const dialog = this.dialog.open(SetPhoneComponent, { data: user });
      dialog.afterClosed().subscribe((result) => {
        if (!result) {
          const data = {
            procedenciaComoLead: 'NA',
          };
          this.apiService.put({ api: this.USER_PUT_PHONE + user._id, data }).subscribe((respond) => {
            user.procedenciaComoLead = data.procedenciaComoLead;
            this.cookieService.set('user', JSON.stringify(user));
            localStorage.setItem('user', JSON.stringify(user));
          });
        } else {
          this.isPremiumUser.next(!!result.code);
          this.alertService.showSuccess({ msg: 'Se ha registrado la información correctamente' });
        }
      });
    }
  }

  public getUserSocialAuthentication({ callback }: { callback?: Function }, socialType: string) {
    return new Promise((success) => {
      this.socialAuthService.authState.subscribe((user) => {
        if (user != null) {
          const userTemplate = {
            email: user.email,
            nombre: user.firstName,
            apellido: user.lastName,
            tipoRedSocial: socialType,
          };
          this.socialAuthentication({ user: userTemplate }).subscribe(
            (response) => {
              if (response.success) {
                const userData = JSON.stringify(response.user);
                const favoritos = JSON.stringify(response.user.favoritos);
                localStorage.setItem('Favoritos', favoritos);
                localStorage.setItem('tipoUser', JSON.stringify(response.user.tipo));
                this.saveCredentials({ user: userData, token: response.token });
                this.setLoggedIn({ status: true });
                const code = response.user.procedenciaComoLead;
                this.isPremiumUser.next(code && code !== 'NA');
                this.socialLoggedIn.next(true);
                if (callback) {
                  callback();
                  this.navigationService.navigateTo({ path: 'home' });
                  this.verifyPhoneUser();
                } else {
                  const intendedUrl = this.getIntendedUrl();
                  this.verifyPhoneUser();
                  if (intendedUrl !== null && intendedUrl !== undefined) {
                    this.navigationService.navigateTo({ path: intendedUrl });
                  } else {
                    this.navigationService.navigateTo({ path: 'home' });
                  }
                  success(true);
                }
              } else {
                alert(response.msg);
                success(false);
              }
            },
            (err) => {
              this.alertService.showError({ msg: 'There was a problem logging in, please try again later' });
              success(false);
            }
          );
        } else {
          success(false);
        }
      });
    });
  }

  public signInWithGoogle({ callback }: { callback?: Function }) {
    return new Promise(async (success) => {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID, { scope: 'profile email' });
      const respond = await this.getUserSocialAuthentication({ callback }, 'Google');
      success(respond);
    });
  }

  public signInWithFB({ callback }: { callback?: Function }) {
    return new Promise(async (success) => {
      this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
      const respond = await this.getUserSocialAuthentication({ callback }, 'Facebook');
      success(respond);
    });
  }

  signOut(): void {
    if (this.socialLoggedIn.getValue()) {
      this.socialAuthService.signOut();
    }
  }

  getCurrentLoggedUser(): string {
    const user = this.cookieService.get('user');
    return user;
  }

  /**
   * Authenticate with social plugin (google or facebook)
   * @param user user respond plugin
   */
  socialAuthentication({ user }): Observable<any> {
    return this.http.post(`${this.baseUrl}users/authenticate-social-log`, user, { headers: this.headers });
  }

  /**
   * Authenticates a user in the backend, given that the user email-password combination is ok
   * @param email user email
   * @param password user password
   */
  authenticateUser({ email, password }: { email: string; password: string }): Observable<any> {
    return this.http.post(
      `${this.baseUrl}${this.LOGIN_API}`,
      {
        email,
        password,
      },
      { headers: this.headers }
    );
  }

  /**
   * Stores user credentials in local storage
   * @param user user
   * @param token backend access token
   */
  saveCredentials({ user, token }: { user: string; token: string }): void {
    this.cookieService.deleteAll();
    this.cookieService.set('user', user);
    this.cookieService.set('U360token', token);
    localStorage.setItem('user', user);
    const jsonUser = JSON.parse(user);
    const name = jsonUser.primerNombre ? jsonUser.primerNombre : jsonUser.nombre;
    localStorage.setItem('username', name);
    this.userName.next(name);
  }

  /**
   * Changes the status of the logged in variable
   * @param status true if the user is logged in false if the user is logges out
   */
  setLoggedIn({ status }: { status: boolean }): void {
    this.loggedIn.next(status);
    localStorage.setItem('loggedIn', JSON.stringify(status));
  }

  /**
   * logs out user
   */
  logout(): void {
    this.cookieService.delete('user');
    this.cookieService.delete('schoolId');
    this.cookieService.delete('U360token');
    this.cookieService.deleteAll();
    localStorage.removeItem('user');
    localStorage.removeItem('favoritos');
    localStorage.removeItem('visited');
    localStorage.removeItem('tipoUser');
    localStorage.removeItem('schoolId');
    localStorage.removeItem('U360token');
    localStorage.removeItem('tokenSchool');
    localStorage.removeItem('username');
    localStorage.removeItem('loggedIn');
    this.loggedIn.next(false);
    this.userName.next('');
    this.userType.next('');
    this.isPremiumUser.next(false);
    this.signOut();
  }

  /**
   * Handles user registration
   * @param nombre: name
   * @param apellido: last name
   * @param email: email
   * @param password: password
   * @param tipo: user type (should always be "Estudiante")
   */
  register({
    nombre,
    apellido,
    email,
    password,
    tipo,
    telefono,
    procedenciaComoLead,
    confirmado,
  }: {
    nombre: string;
    apellido: string;
    email: string;
    password: string;
    tipo: string;
    telefono: string;
    procedenciaComoLead: string;
    confirmado: boolean;
  }): Observable<any> {
    return this.http.post(
      `${this.baseUrl}${this.REGISTER_API}`,
      {
        nombre,
        apellido,
        email,
        password,
        tipo,
        telefono,
        procedenciaComoLead,
        confirmado,
      },
      { headers: this.headers }
    );
  }

  /** Sets the intended url on an unauthorized access attempt, will be used to redirect the user after successful login
   * @param url url
   */
  setIntendedUrl({ url }: { url: string }): void {
    this.intendedUrl = url;
  }

  /** returns the intended url that was saved on the setIntendedurl method */
  getIntendedUrl(): string {
    return this.intendedUrl;
  }
}
