import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-reusable-form',
  templateUrl: './reusableForm.component.html',
  styleUrls: ['./reusableForm.component.scss'],
})
export class ReusableFormComponent implements OnInit {
  @Input() data: any;
  form: FormGroup;
  currentPage: number = 1;
  totalPages: number = 5;
  universitiesOfColombia: any;
  abroadUniversities: any;
  citiesOfColombia: any;
  age: any;
  user = JSON.parse(localStorage.getItem('user'));
  userFormData: any;
  readonly getColombiaUniversities = 'api/universidad';
  readonly countries = 'api/pais/';
  readonly cities = 'api/ciudad/pais/';
  readonly userApi = 'api/oneUser/';
  readonly PutUserApi = 'api/user/';

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<ReusableFormComponent>,
    @Inject(PLATFORM_ID) private plaformId
  ) { }
  userId = JSON.parse(localStorage.getItem('user'));
  ngOnInit(): void {
    this.createForm();
    const citiesURL = `${this.cities}63f7c7981eb43b0012ea3d84`;
    const url = `${this.getColombiaUniversities}`;
    const countriesURL = `${this.countries}`;

    if (this.form.get('birthDate')?.value) {
      this.calculateAge();
    }
    this.apiService.get({ api: url }).subscribe(
      (response) => {
        this.universitiesOfColombia = response.docs
          .filter((university) => university.ciudad.pais.nombre === 'Colombia')
          .map((university) => university.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: countriesURL }).subscribe(
      (response) => {
        this.abroadUniversities = response.map((university) => university.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: citiesURL }).subscribe(
      (response) => {
        this.citiesOfColombia = response.map((city) => city.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );
  }
  closeWithoutSaving() {
    this.dialogRef.close();
  }
  createForm() {
    this.form = this.fb.group({
      userId: [this.userId._id],
      school: [this.data.school || ''],
      desiredCareer: [this.data.desiredCareer || ''],
      schoolCity: [this.data.schoolCity || ''],
      currentGrade: [this.data.currentGrade || ''],
      gradeLevel: [this.data.gradeLevel || ''],
      name: [this.data.name || ''],
      secondName: [this.data.secondName || ''],
      lastName: [this.data.lastName || ''],
      secondLastName: [this.data.secondLastName || ''],
      typeOfDocument: [this.data.typeOfDocument || ''],
      documentNumber: [this.data.documentNumber || ''],
      schoolEmail: [this.data.schoolEmail || ''],
      personalEmail: [this.data.personalEmail || ''],
      verifiedPhone: [this.data.verifiedPhone || ''],
      hasPhone: [this.data.hasPhone || false],
      verifiedWhatsApp: [this.data.verifiedWhatsApp || ''],
      hasWhatsApp: [this.data.hasWhatsApp || false],
      linkedin: [this.data.linkedin || ''],
      xProfile: [this.data.xProfile || ''],
      tiktokProfile: [this.data.tiktokProfile || ''],
      instagramProfile: [this.data.instagramProfile || ''],
      facebookProfile: [this.data.facebookProfile || ''],
      consent: [this.data.consent || false],
      birthDate: [this.data.birthDate || null],
      gender: [this.data.gender || ''],
      subscribe: [this.data.subscribe || false],
      studyInterest: [this.data.studyInterest || ''],
      univertiesSelected: [this.data.univertiesSelected || []],
      englishLevel: [this.data.englishLevel || ''],
      colombianUniversities: this.fb.array(this.data.colombianUniversities || []),

      abroadCountries: this.fb.array(this.data.abroadCountries || []),
      primaryCity: [this.data.primaryCity || ''],
      secondaryCity: [this.data.secondaryCity || ''],
      careerInterest: [this.data.careerInterest || []],
      estimatedStartDate: [this.data.estimatedStartDate || null],
      academicRatings7: [this.data.academicRatings7 || 'Aun no curso'],
      academicRatings8: [this.data.academicRatings8 || 'Aun no curso'],
      academicRatings9: [this.data.academicRatings9 || 'Aun no curso'],
      academicRatings10: [this.data.academicRatings10 || 'Aun no curso'],
      academicRatings11: [this.data.academicRatings11 || 'Aun no curso'],
      academicRatings12: [this.data.academicRatings12 || 'Aun no curso'],
      guardianName: [this.data.guardianName || ''],
      guardianEmail: [this.data.guardianEmail || ''],
      guardianPhone: [this.data.guardianPhone || ''],
      declaration: [this.data.declaration || false],
      isCreatedAlready: [this.data.isCreatedAlready || false],
    });
  }
  calculateAge() {
    const birthDate = this.form.get('birthDate')?.value;

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.age = age;
    // return age;
  }
  validatePage(pageNumber: number, age: number): boolean {
    switch (pageNumber) {
      case 1:
        return this.validatePageOne();
      case 2:
        return this.validatePageTwo();
      case 3:
        return this.validatePageThree();
      case 4:
        return this.validatePageFour();
      case 5:
        if (age < 18) {
          return this.validatePageFive();
        } else {
          return true; // O puedes retornar false si no quieres que pase la validación para mayores de 18
        }
      default:
        return false;
    }
  }

  validatePageOne(): boolean {
    return (
      this.form.get('school')?.value.trim() !== '' &&
      this.form.get('schoolCity')?.value.trim() !== '' &&
      this.form.get('currentGrade')?.value.trim() !== '' &&
      this.form.get('gradeLevel')?.value.trim() !== '' &&
      this.form.get('name')?.value.trim() !== '' &&
      this.form.get('lastName')?.value.trim() !== '' &&
      this.form.get('typeOfDocument')?.value.trim() !== '' &&
      this.form.get('documentNumber')?.value.trim() !== '' &&
      this.form.get('birthDate')?.value !== null &&
      this.form.get('gender')?.value.trim() !== ''
    );
  }

  validatePageTwo(): boolean {
    return (
      this.form.get('schoolEmail')?.value.trim() !== '' &&
      this.form.get('personalEmail')?.value.trim() !== '' &&
      this.form.get('verifiedPhone')?.value !== '' &&
      this.form.get('consent')?.value !== ''
    );
  }

  validatePageThree(): boolean {
    return this.form.get('primaryCity')?.value.trim() !== '' && this.form.get('secondaryCity')?.value.trim() !== '';
  }

  validatePageFour(): boolean {
    return (
      this.form.get('academicRatings7')?.value.trim() !== '' &&
      this.form.get('academicRatings8')?.value.trim() !== '' &&
      this.form.get('academicRatings8')?.value.trim() !== '' &&
      this.form.get('academicRatings8')?.value.trim() !== '' &&
      this.form.get('academicRatings11')?.value !== null &&
      this.form.get('academicRatings12')?.value !== null &&
      this.form.get('englishLevel')?.value !== ''
    );
  }

  validatePageFive(): boolean {
    return (
      this.form.get('guardianName')?.value.trim() !== '' &&
      this.form.get('guardianEmail')?.value.trim() !== '' &&
      this.form.get('guardianPhone')?.value.trim() !== ''
    );
  }

  nextPage() {
    if (this.validatePage(this.currentPage, this.age)) {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'Por favor, completa todos los campos requeridos en esta página.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  submitData(type: string) {
    const postData = {
      api: 'api/createInitialDataForm',
      data: this.form.value,
    };
    const updateData = {
      api: `api/updateInitialDataForm/${this.data._id}`,
      data: this.form.value,
    };

    if (!this.form.value.isCreatedAlready) {
      this.apiService.post(postData).subscribe(
        (response) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Éxito',
              text: 'Se han guardado los cambios correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(); // Cierra el diálogo después de que el usuario confirme
            });
          }
        },
        (error) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Error',
              text: 'No se pudieron guardar los cambios. Inténtalo de nuevo.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
          console.error('Error submitting questions and answers:', error);
        }
      );
    } else {
      this.apiService.put(updateData).subscribe(
        (response) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Éxito',
              text: 'Se ha actualizado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(); // Cierra el diálogo después de que el usuario confirme
            });
          }
        },
        (error) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Error',
              text: 'No se pudieron actualizar los cambios. Inténtalo de nuevo.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
          console.error('Error submitting questions and answers:', error);
        }
      );

      const getUserUrl = `${this.userApi}${this.user._id}`;
      this.apiService.get({ api: getUserUrl }).subscribe(
        (response) => {
          this.userFormData = response;

          if (this.userFormData.primerNombre) {
            this.userFormData.primerNombre = this.form.get('name')?.value;
            this.userFormData.segundoNombre = this.form.get('secondName')?.value;
            this.userFormData.primerApellido = this.form.get('lastName')?.value;
            this.userFormData.segundoApellido = this.form.get('secondLastName')?.value;
            this.userFormData.birthDay = this.form.get('birthDate')?.value;
            this.userFormData.grade = this.form.get('currentGrade')?.value;
            this.userFormData.gradeLevel = this.form.get('gradeLevel')?.value;
            this.userFormData.sexo = this.form.get('gender')?.value;
            this.userFormData.telefono = this.form.get('verifiedPhone')?.value;
          }

          const data = this.userFormData;
          this.apiService.put({ api: this.PutUserApi + this.user._id, data }).subscribe(
            (response) => {
              if (isPlatformBrowser(this.plaformId)) {
                const storedUser = JSON.parse(localStorage.getItem('user'));
                const updatedUser = { ...storedUser, ...this.userFormData };
                localStorage.setItem('user', JSON.stringify(updatedUser));
              }
            },
            (err) => {
              console.error('Error submitting personal info:', err);
            }
          );
        },
        (error) => {
          console.error('Error fetching user data:', error);
        }
      );
    }
  }

  submitDataAndDoOtherThings() {
    const validationResult = this.isValidForUpdate(this.form.value);

    if (validationResult.isValid) {
      const updateData = {
        api: `api/publishInitialDataForm/${this.data._id}`,
        data: this.form.value,
      };

      this.apiService.put(updateData).subscribe(
        (response) => {
          Swal.fire({
            title: 'Éxito',
            text: 'Se han publicado los cambios correctamente.',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            if (isPlatformBrowser(this.plaformId)) {
              const storedUser = JSON.parse(localStorage.getItem('user'));
              const updatedUser = { ...storedUser, ...response.userResult };
              localStorage.setItem('user', JSON.stringify(updatedUser));
            }

            this.dialogRef.close(); // Cierra el diálogo después de que el usuario confirme
          });
        },
        (error) => {
          console.error('Error submitting questions and answers:', error);
          Swal.fire({
            title: 'Error',
            text: 'No se pudieron publicar los cambios. Inténtalo de nuevo.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      );
    } else {
      console.error('Please fill in all required fields.');
      Swal.fire({
        title: 'Advertencia',
        text: `Por favor, completa los siguientes campos requeridos: ${validationResult.missingFields.join(', ')}.`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  isValidForUpdate(formValue: any): { isValid: boolean; missingFields: string[] } {
    const requiredFields = [
      // 'school',
      // 'schoolCity',
      'currentGrade',
      'gradeLevel',
      'name',
      'lastName',
      // 'schoolEmail',
      'personalEmail',
      'verifiedPhone',
      'verifiedWhatsApp',
      'consent',
      'birthDate',
      'gender',
      'subscribe',
      'studyInterest',
      'careerInterest',
      'estimatedStartDate',
      'academicRatings7',
      'academicRatings8',
      'academicRatings9',
      'academicRatings10',
      'academicRatings11',
      'academicRatings12',
      'guardianName',
      'guardianEmail',
      'guardianPhone',
      'declaration',
    ];

    // Encontrar los campos faltantes
    const missingFields = requiredFields.filter(
      (field) => formValue[field] === null || formValue[field] === '' || formValue[field] === undefined
    );

    return {
      isValid: missingFields.length === 0,
      missingFields: missingFields,
    };
  }
}
