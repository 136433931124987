<div class="school-comparison-dashboard">
  <div class="chartsContainer">
    <div class="coming-soon-message">
      <p class="coming-soon-text">Muy pronto estaremos disponibles</p>
    </div>
  </div>
  <!--
  <div class="charts-container">
    <div class="stackedBar">
      <p class="skillsLevelTitle">Cantidad de estudiantes por nivel de inglés.</p>
      <apx-chart
        [fill]="languagesChart.fill"
        [options]="languagesChart.options"
        [series]="languagesChart.series"
        [chart]="languagesChart.chart"
        [plotOptions]="languagesChart.plotOptions"
        [dataLabels]="languagesChart.dataLabels"
        [legend]="languagesChart.legend"
        [xaxis]="languagesChart.xaxis"
        [labels]="languagesChart.labels"
        [responsive]="languagesChart.responsive"
        [grid]="languagesChart.grid"
        [stroke]="languagesChart.stroke"
        [theme]="languagesChart.theme"></apx-chart>
    </div>
  </div>
  -->
</div>

<style>
  .chartsContainer {
    background-color: #f7f7f792;
    margin-top: 20px;
    padding: 2em;
    width: 93%;
    margin: 20px auto; /* Center horizontally */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; 
    position: relative;
  }

  .coming-soon-message {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .coming-soon-text {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    background-color: #545ba8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .skillsLevelsContainer {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 8px;
  }

  .stackedBarContainer {
    margin-top: 2em;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .stackedBar {
    width: 48%;
    background-color: white;
    border-radius: 10px;
    padding: 8px;
  }

  .skillsLevelTitle {
    font-size: 20px;
    font-weight: bold;
    margin-left: 1em;
    margin-top: 20px;
  }

  .filterContainer {
    display: flex;
    margin-bottom: 15px;
    justify-content: right;
    padding-right: 0.5em;
  }

  .skillsfilterContainer {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    padding-right: 0.5em;
  }

  .gradeFilter {
    background-color: white !important;
    border-radius: 10px;
    padding: 5px;
  }

  .skillsFilter {
    margin-top: 20px;
    height: 50px;
    justify-self: right;
    background-color: #f7f7f792;
    border-radius: 10px;
    padding: 5px;
  }

  .clearFilters {
    color: #898585;
    font-weight: lighter;
    background-color: white;
    border-radius: 10px;
    padding: 0 0.5em;
    margin-right: 1.7em;
    box-shadow: none;
  }

  ::ng-deep .mat-form-field-underline {
    display: none !important;
  }

  ::ng-deep .mat-select-arrow {
    width: 0;
    height: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    border-top: unset !important;
  }

  ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
    display: flex;
    align-items: center;
    border-top-width: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: unset;
  }

  ::ng-deep .mat-select-value-text {
    color: #898585;
    font-weight: lighter;
  }
</style>