<div class="container pt-4">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Progreso General</h4>
      <p>
        En esta sección podrás encontrar como has mejorado tus competencias individuales en el tiempo, es un termómetro
        para conocer dónde estás y hacia dónde quieres llegar.
      </p>
      <hr class="hrLine" />
    </div>
  </div>
  <div class="d-flex justify-content-end mb-3">
    <mat-form-field class="mr-3">
      <mat-label>Año escolar</mat-label>
      <mat-select [(value)]="selectedQuarter" (selectionChange)="onQuarterSelection($event.value)">
        <mat-option *ngFor="let quarter of grades" [value]="quarter">{{ quarter }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Competencia</mat-label>
      <mat-select [(value)]="selectedSkill" (selectionChange)="onSkillSelection($event.value)">
        <mat-option *ngFor="let skill of this.skillsForFilter" [value]="skill">{{ skill }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- <div id="chart" *ngIf="selectedQuarterMonths"> -->
  <div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [annotations]="chartOptions.annotations"></apx-chart>
  </div>
  <!-- <div id="chart" *ngIf="!selectedQuarterMonths">
    <h4 class="titulo-wizard">No tienes experiencias en este año</h4>
  </div> -->
</div>
