import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { S3Service } from 'src/app/public/services/s3.service';
import { NavigationService } from 'src/app/public/services/navigation.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  readonly apiPost = 'api/hoja-vida/create';
  readonly apiGet = 'api/hoja-vida/getById/';
  readonly apiPut = 'api/hoja-vida/update/';
  readonly apiGetInitialForm = 'api/getInitialDataFormById/';
  readonly apiUpdateInitialForm = 'api/updateInitialDataForm/';
  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser/';
  readonly apiCountries = 'api/pais';
  // readonly GET_LOGOS_UNIVERSITIES = 'api/universidades/logos';
  readonly universitiesAPI = 'api/universidad';

  /** Variable that holds the login form */
  editar: FormGroup;
  discipline: Array<any> = [];
  /** Variable that holds the id of the current user */
  id: string;
  idHoja: string;
  userInitialForm: any;
  readonly settingsA: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    itemsShowLimit: 3,
  };

  englishLevels = [
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
  ];

  areas = [
    {
      nombre: 'Artes, Diseño y Arquitectura',
      selected: false,
    },
    {
      nombre: 'Negocios y Gestión',
      selected: false,
    },
    {
      nombre: 'Ciencias Computacionales y TI',
      selected: false,
    },
    {
      nombre: 'Ingeniería',
      selected: false,
    },
    {
      nombre: 'Ciencias y Matemáticas',
      selected: false,
    },
    {
      nombre: 'Ciencias Sociales y Humanidades',
      selected: false,
    },
    {
      nombre: 'Educación y Deporte',
      selected: false,
    },
    {
      nombre: 'Servicios Comunitarios',
      selected: false,
    },
    {
      nombre: 'Estudios Ambientales y Ciencias de la Tierra',
      selected: false,
    },
    {
      nombre: 'Salud',
      selected: false,
    },
    {
      nombre: 'Periodismo y Medios',
      selected: false,
    },
    {
      nombre: 'Derecho y Ciencias Políticas',
      selected: false,
    },
    {
      nombre: 'Idiomas',
      selected: false,
    },
    {
      nombre: 'Turismo y Hospitalidad',
      selected: false,
    },
    {
      nombre: 'Otros',
      selected: false,
    },
  ];
  paises: any;

  dataUniversidades: any;
  universitiesSelected = [];
  readonly optionsUniversities: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 3,
    enableCheckAll: false,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };

  userTest: any;
  user = JSON.parse(localStorage.getItem('user'));
  paisesFiltrados = [];
  selectedCountries = [];
  areasFiltradas = [];
  linked: any;
  fotoBaner: any;
  fotoPerfil: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí  (máx. 125 palabras)',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '3',
    fonts: [{ class: 'arial', name: 'Arial' }],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript', 'heading', 'fontName'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode',
      ],
    ],
  };
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private formBuilderService: FormBuilder,
    @Inject(PLATFORM_ID) private plaformId,
    private ngxSpinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private ngxSpinner: NgxSpinnerService,
    private s3Service: S3Service,
    private navigationService: NavigationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.editar = this.formBuilderService.group({
      nombre: ['', [Validators.required]],
      colegio: ['', [Validators.required]],
      proyecto: ['', [Validators.required]],
      palabrasClave: ['', [Validators.required]],
      individualProfile: ['', [Validators.required]],
      achievements: ['', [Validators.required]],
      academicRecord: ['', [Validators.required]],
      employmentHistory: ['', [Validators.required]],
      relevantSkills: ['', [Validators.required]],
      aditionalFormation: ['', [Validators.required]],
      sobreMi: ['', [Validators.required]],
      idioma1: ['Inglés', [Validators.required]],
      idioma1Nivel: ['', [Validators.required]],
      idioma2: ['', [Validators.required]],
      idioma2Nivel: ['', [Validators.required]],
      idioma3: ['', [Validators.required]],
      idioma3Nivel: ['', [Validators.required]],
      idioma4: ['', [Validators.required]],
      idioma4Nivel: ['', [Validators.required]],
      logros: this.formBuilderService.array([]),
      referencias: this.formBuilderService.array([]),
      universities: this.formBuilderService.array([]),
    });

    try {
      await this.getCountries();
      await this.getUserinfo();
      await this.getUniversitiesLogos();
      await this.getUserInitialForm();
    } catch (error) {
      console.error('Error in initial process:', error);
    }
  }

  async getUserinfo(): Promise<void> {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.apiGet + this.user._id }).subscribe(
      (response) => {
        if (response) {
          this.idHoja = response._id;
          const userName = this.user.nombre ? this.user.nombre : this.user.primerNombre;
          const lastName = this.user.apellido ? this.user.apellido : this.user.primerApellido;
          this.editar.get('nombre').setValue(userName + ' ' + lastName);
          this.editar.get('colegio').setValue(response.colegio);
          this.editar.get('proyecto').setValue(response.proyecto);
          this.editar.get('palabrasClave').setValue(response.palabrasClave);
          this.editar.get('sobreMi').setValue(response.sobreMi);
          this.editar.get('individualProfile').setValue(response.individualProfile);
          this.editar.get('achievements').setValue(response.achievements);
          this.editar.get('academicRecord').setValue(response.academicRecord);
          this.editar.get('employmentHistory').setValue(response.employmentHistory);
          this.editar.get('relevantSkills').setValue(response.relevantSkills);
          this.editar.get('aditionalFormation').setValue(response.aditionalFormation);
          this.linked = response.link ? response.link : null;
          // this.universitiesSelected = response.universidadInteres;
          // this.selectedCountries = response.paisInteres;
          for (const [index, idioma] of response.idioma.entries()) {
            // if (index == 0) {
            //   this.editar.get('idioma1').setValue(idioma.nombre);
            //   this.editar.get('idioma1Nivel').setValue(idioma.nivel);
            // }
            if (index == 1) {
              this.editar.get('idioma2').setValue(idioma.nombre);
              this.editar.get('idioma2Nivel').setValue(idioma.nivel);
            }
            if (index == 2) {
              this.editar.get('idioma3').setValue(idioma.nombre);
              this.editar.get('idioma3Nivel').setValue(idioma.nivel);
            }
            if (index == 3) {
              this.editar.get('idioma4').setValue(idioma.nombre);
              this.editar.get('idioma4Nivel').setValue(idioma.nivel);
            }
          }

          // for (const area of response.areaInteres) {
          //   for (const areaSelect of this.areas) {
          //     if (area.nombre == areaSelect.nombre) {
          //       areaSelect.selected = true;
          //       this.areasFiltradas.push(areaSelect);
          //     }
          //   }
          // }

          for (const logro of response.logros) {
            const logroForm = this.formBuilderService.group({
              descripcion: logro.descripcion,
            });
            this.logros.push(logroForm);
          }

          for (const referencia of response.referencias) {
            const referenciaForm = this.formBuilderService.group({
              nombre: referencia.nombre,
              cargo: referencia.cargo,
              nota: referencia.nota,
            });
            this.referencias.push(referenciaForm);
          }

          if (response.fotoBaner) {
            this.fotoBaner = response.fotoBaner;
          }

          if (response.fotoPerfil) {
            this.fotoPerfil = response.fotoPerfil;
          }

          this.ngxSpinnerService.hide();
        } else {
          this.editar.get('nombre').setValue(this.user.nombre + ' ' + this.user.apellido);
          this.ngxSpinnerService.hide();
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        });
      }
    );
  }

  async getUserInitialForm(): Promise<void> {
    this.apiService.get({ api: this.apiGetInitialForm + this.user._id }).subscribe(
      (response) => {
        if (response) {
          this.userInitialForm = response;

          this.selectedCountries = response.abroadCountries || [];
          this.universitiesSelected = response.colombianUniversities || [];
          this.editar.get('idioma1Nivel').setValue(response.englishLevel || '');

          for (const area of response.careerInterest) {
            for (const areaSelect of this.areas) {
              if (area == areaSelect.nombre) {
                areaSelect.selected = true;
                this.areasFiltradas.push(areaSelect);
              }
            }
          }
        }
      },
      (err) => {
        console.error('Error fetch initial form: ', err);
      }
    );
  }

  /**
   * Handles login form submission
   */
  onSubmit(): void {
    this.save();
  }

  eliminarReferencia1(): void {
    this.editar = this.formBuilderService.group({
      referencia1Nombre: ['', [Validators.required]],
      referencia1Cargo: ['', [Validators.required]],
      referencia1Nota: ['', [Validators.required]],
    });
  }

  eliminarReferencia2(): void {
    this.editar = this.formBuilderService.group({
      referencia2Nombre: ['', [Validators.required]],
      referencia2Cargo: ['', [Validators.required]],
      referencia2Nota: ['', [Validators.required]],
    });
  }

  save() {
    this.ngxSpinnerService.show();

    if (this.selectedCountries.length > 0) {
      const initialFormData = { ...this.userInitialForm };
      initialFormData.abroadCountries = this.selectedCountries;
      initialFormData.englishLevel = this.editar.get('idioma1Nivel').value;
      initialFormData.colombianUniversities = this.universitiesSelected;
      initialFormData.careerInterest = this.areasFiltradas.map((area) => area.nombre);

      this.apiService.put({ api: this.apiUpdateInitialForm + initialFormData._id, data: initialFormData }).subscribe(
        (response) => {
          console.log('Formulario inicial actualizado con éxito.');
        },
        (err) => {
          this.alertService.showError({ msg: 'Error actualizando el formulario inicial.' });
        }
      );
    }

    let dataIdiomas = [];

    if (this.editar.get('idioma1').value && this.editar.get('idioma1Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma1').value,
        nivel: this.editar.get('idioma1Nivel').value,
      });
    }
    if (this.editar.get('idioma2').value && this.editar.get('idioma2Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma2').value,
        nivel: this.editar.get('idioma2Nivel').value,
      });
    }
    if (this.editar.get('idioma3').value && this.editar.get('idioma3Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma3').value,
        nivel: this.editar.get('idioma3Nivel').value,
      });
    }
    if (this.editar.get('idioma4').value && this.editar.get('idioma4Nivel').value) {
      dataIdiomas.push({
        nombre: this.editar.get('idioma4').value,
        nivel: this.editar.get('idioma4Nivel').value,
      });
    }

    let data: any = {
      colegio: this.editar.get('colegio').value,
      proyecto: this.editar.get('proyecto').value,
      palabrasClave: this.editar.get('palabrasClave').value,
      sobreMi: this.editar.get('sobreMi').value,
      individualProfile: this.editar.get('individualProfile').value,
      achievements: this.editar.get('achievements').value,
      academicRecord: this.editar.get('academicRecord').value,
      employmentHistory: this.editar.get('employmentHistory').value,
      relevantSkills: this.editar.get('relevantSkills').value,
      aditionalFormation: this.editar.get('aditionalFormation').value,
      idioma: dataIdiomas,
      areaInteres: this.areasFiltradas,
      paisInteres: this.selectedCountries.map((pais) => ({ nombre: pais })),

      logros: this.editar.get('logros').value,
      referencias: this.editar.get('referencias').value,
      universidadInteres: this.universitiesSelected,
      link: this.linked,
    };

    if (!this.idHoja) {
      data.usuario = this.user._id;
      this.apiService.post({ api: this.apiPost, data }).subscribe(
        (response) => {
          if (response.success) {
            this.alertService.showSuccess({
              msg: 'Se ha creado la hoja de vida de manera exitosa',
            });
          } else {
            this.alertService.showError({
              msg: 'Ha ocurrido un error.',
            });
          }

          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        },
        (err) => {
          this.alertService.showError({ msg: 'Ha ocurrido un error.' });
        }
      );
    } else {
      this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe(
        (response) => {
          this.alertService.showSuccess({
            msg: 'Se han actualizado los datos con exito',
          });
          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        },
        (err) => {
          this.alertService.showError({ msg: 'Ha ocurrido un error.' });
          this.ngxSpinnerService.hide();
        }
      );
    }
  }

  backToCurriculum() {
    this.navigationService.navigateTo({ path: 'curriculum' });
  }

  paisAreaInteres(type, value, index) {
    if (type == 'area') {
      const isSelected = this.areas[index].selected;

      if (!isSelected && this.areasFiltradas.length >= 2) {
        Swal.fire({
          title: 'Error',
          text: 'Solo puedes seleccionar hasta 2 áreas.',
          icon: 'error',
          confirmButtonText: 'OK',
        });

        this.areasFiltradas = this.areasFiltradas.slice(0, 5);
        return;
      }

      this.areas[index].selected = !isSelected;

      if (this.areas[index].selected) {
        this.areasFiltradas.push(this.areas[index]);
      } else {
        this.areasFiltradas = this.areasFiltradas.filter(
          (e) => this.areas[index].nombre != e.nombre
        );
      }
    }
  }

  get logros(): FormArray {
    return this.editar.get('logros') as FormArray;
  }
  addLogros() {
    const logrosAgregar = this.formBuilderService.group({
      descripcion: '',
    });
    this.logros.push(logrosAgregar);
  }

  removeLogros({ index }) {
    this.logros.removeAt(index);
  }

  get referencias(): FormArray {
    return this.editar.get('referencias') as FormArray;
  }

  addReferencia() {
    const referencia = this.formBuilderService.group({
      nombre: '',
      cargo: '',
      nota: '',
    });
    this.referencias.push(referencia);
  }

  removeReferencia({ index }) {
    this.referencias.removeAt(index);
  }

  /**
   * Uploads an image into S3
   * @param event image upload event
   */
  onChangeProfileImage({ event }: { event: any }): void {
    if (event.target.files.length > 1) {
      alert('Por favor sube una unica imagen');
    } else {
      this.ngxSpinner.show();
      const validImageTypes = ['image/jpeg', 'image/png'];
      const file = event.target.files[0];
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        alert('Por favor sube una imagen en formato jpg o png');
        this.ngxSpinner.hide();
      } else {
        const key = `user/${this.user._id}/profile-curriculum-image/`;

        const callback = (err, data) => {
          if (err) {
            this.ngxSpinner.hide();
            this.alertService.showError({
              msg: 'Hubo un error subiendo la imagen de perfil',
            });
          } else {
            let updateData = {
              fotoPerfil: data.Location,
            };

            this.updateHojaVida({ data: updateData });
            this.ngxSpinner.hide();
            this.backToCurriculum();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }

  uploadBannerImage({ event }) {
    if (event.target.files.length > 1) {
      alert('Por favor sube una unica imagen');
    } else {
      this.ngxSpinnerService.show();
      const validImageTypes = ['image/jpeg', 'image/png'];
      const file = event.target.files[0];
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        alert('Por favor sube una imagen en formato jpg o png');
        this.ngxSpinnerService.hide();
      } else {
        const key = `user/${this.user._id}/banner-image/`;

        const callback = (err, data) => {
          if (err) {
            this.ngxSpinnerService.hide();
            this.alertService.showError({
              msg: 'Hubo un error subiendo la imagen del baner',
            });
          } else {
            let updateData = {
              fotoBaner: data.Location,
            };
            this.updateHojaVida({ data: updateData });
            this.ngxSpinnerService.hide();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }

  /**
   * Updates the user information in the backend
   */
  updateProfilePhoto({ data }: { data: any }): void {
    this.ngxSpinner.show();
    this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe(
      (response) => {
        if (response.success === false) {
          this.alertService.showError({
            msg: this.translate.instant('Profile.upload-image-error'),
          });
        } else {
          this.ngOnInit();
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Profile.upload-image-error'),
        });
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  updateHojaVida({ data }) {
    this.apiService.put({ api: this.apiPut + this.idHoja, data }).subscribe((response) => {
      this.backToCurriculum();
    });
  }

  getTestInternationalization({ userId }) {
    this.apiService.get({ api: this.GET_TEST + userId }).subscribe((response) => {
      this.userTest = response;
    });
  }
  async getCountries() {
    this.apiService.get({ api: this.apiCountries }).subscribe((response) => {
      this.paises = response.map((country) => country.nombre);
    });
  }
  async getUniversitiesLogos() {
    this.apiService.get({ api: this.universitiesAPI }).subscribe((response) => {
      this.dataUniversidades = Array.from(
        new Set(
          response.docs
            .filter((university) => university.ciudad.pais.nombre === 'Colombia')
            .map((university) => university.nombre)
        )
      );
    });
  }
  onChangeCountry(event: any): void {
    if (this.selectedCountries.length > 3) {
      Swal.fire({
        title: 'Error',
        text: 'Solo puedes seleccionar un máximo de 3 países.',
        icon: 'error',
        confirmButtonText: 'OK',
      });

      this.selectedCountries = this.selectedCountries.slice(0, 3);
      return;
    }

    const countryExists = this.selectedCountries.some(
      (country) => country.nombre === event.nombre
    );
    if (!countryExists) {
      this.selectedCountries.push(event);
    }
  }

  onDeSelectCountry(event) {
    this.selectedCountries = this.selectedCountries.filter((country) => country !== event);
  }

  onSelect(event) {
    // for (const item of this.dataUniversidades) {
    //   if (item.nombre == event.nombre) {
    //     this.universitiesSelected.push(item);
    //   }
    // }

    if (this.universitiesSelected.length > 5) {
      Swal.fire({
        title: 'Error',
        text: 'Solo puedes seleccionar un máximo de 5 universidades.',
        icon: 'error',
        confirmButtonText: 'OK',
      });

      this.universitiesSelected = this.universitiesSelected.slice(0, 5);
      return;
    }

    const countryExists = this.universitiesSelected.some(
      (country) => country.nombre === event.nombre
    );
    if (!countryExists) {
      this.universitiesSelected.push(event);
    }
  }

  onDeSelect(event) {
    this.universitiesSelected = this.universitiesSelected.filter((uni) => uni.nombre !== event.nombre);
  }
}
