import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { skillsArray } from '../skillsObject/skills.js';
import { ModalService } from './bulkStudents/modal.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-school-students-list',
  templateUrl: './school-students-list.component.html',
  styleUrls: ['./school-students-list.component.scss'],
})
export class SchoolStudentsListComponent implements OnInit {
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly GET_STUDENTS = 'api/skillTestByColegio/student-list';
  gradeLevels = 'api/getGradeLevelsById/';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  visiblePageRange: number[] = [];
  skillOptions = skillsArray;
  studentsPerPage = 25;
  highlightCurrentPage = false;
  ascendingSortOrder: boolean = false;
  descendingSortOrder: boolean = false;
  pageNumbers: number[] = [];
  totalPages: any;
  totalStudents = 0;
  filter = '';
  activePage: number | null = null;
  searchText: string = '';
  selectedGrade: string = '';
  testPresentedFilter: string = '';
  grades: { [key: string]: boolean } = {};
  sortBy: string = '';
  sortOrder: string = '';
  maxPageHorizontal: any;
  schoolData: any;
  studentsData: any;
  nombreColegio = '';
  selectedLink: boolean = false;
  dataSource: any;
  studentData: any;
  studentPerPage: any;
  pageLimit: any;
  pageResult: any;
  hasPrevPage: any;
  hasNextPage: any;
  schoolId: any;
  selectedPage: number = 0;
  prevPage: any;
  showSummaryPages = true;
  nextPage: any;
  link: any;
  linkName: any;
  showAlertLink: boolean = false;
  visibleStudents: any;
  students: any;
  gradesAndLevels: any;
  currentPageHorizontal: number;
  generalScore;
  filteredStudents: any[];
  allStudentsData: any[] = [];
  sizePagination = [];
  user = JSON.parse(localStorage.getItem('user'));
  displayedColumns: string[] = ['nombre', 'grado', 'insignia', 'promedio', 'aplicaciones', 'verperfil'];
  studentFilter: any;
  listTable: any = [];
  allStudent: any;
  selectedGradesLevels: any;
  queryStudents: any = {};
  showFilters: boolean = false;
  showFilterSort: boolean = false;
  showFilterSortUp: boolean = false;
  selectedSkill: string | null = null;
  selectedGradeLevel: any;
  selectedTestPresented = {
    Si: false,
    No: false,
  };
  selectedGrades = {
    '': false,
    '9': false,
    '10': false,
    '11': false,
  };
  selectedOrder = {
    Ascendente: false,
    Descendente: false,
  };

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    document.addEventListener('click', (event) => this.closeFiltersOnOutsideClick(event));
    this.currentPageHorizontal = 0;
    this.getColegio(this.currentPageHorizontal);
    this.getSchoolId();
    this.getGradeLevelsBySchoolId(this.schoolId);
  }
  getSchoolId() {
    this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).subscribe((response: any) => {
      this.schoolId = response._id;
      this.getGradeLevelsBySchoolId(this.schoolId);
    });
  }
  getGradeLevelsBySchoolId(schoolId: string) {
    this.apiService.get({ api: this.gradeLevels + schoolId }).subscribe(
      (response: any) => {
        const formattedGrades = this.formatGrades(response);
        this.gradesAndLevels = formattedGrades;
        if (response) {
        } else {
          console.error('Invalid response structure:', response);
        }
      },
      (error) => {},
      () => {}
    );
  }

  formatGrades(gradesData): { value: string; display: string }[] {
    let formattedGrades = [];
    const gradeLevels = gradesData.gradesLevels;

    gradeLevels.forEach((item) => {
      const grade = item.grade[0];
      const levels = item.levels;

      levels.forEach((levelPosition) => {
        levelPosition.forEach((level) => {
          const formattedGrade = `${grade}${level}`;
          formattedGrades.push({
            value: formattedGrade,
            display: formattedGrade,
          });
        });
      });
    });

    return formattedGrades;
  }
  nextPageHorizontal() {
    if (this.currentPageHorizontal >= 0 && this.currentPageHorizontal < this.totalPages) {
      this.currentPageHorizontal++;
      this.getColegio(this.currentPageHorizontal);
    }
  }

  previousPageHorizontal() {
    if (this.currentPageHorizontal > 0) {
      this.currentPageHorizontal--;
      this.getColegio(this.currentPageHorizontal);
    }
  }
  goToPageHorizontal(page: number) {
    if (this.activePage === page) {
      this.activePage = null;
    } else {
      this.activePage = page;
      this.currentPageHorizontal = page;
      this.getColegio(page);
    }
  }
  openModal() {
    this.modalService.openModal();
  }
  toggleSummaryPages() {
    this.showSummaryPages = !this.showSummaryPages;
  }
  onGradeFilterChange() {
    const selectedGradeValues = Object.keys(this.selectedGrades).filter((key) => this.selectedGrades[key]);
    if (selectedGradeValues.length === 0) {
      this.selectedGrade = '';
    } else {
      this.selectedGrade = selectedGradeValues.join(',');
    }
    console.log('Selected Grades:', this.selectedGrade);
    this.getColegio(this.currentPageHorizontal);
  }
  
  onGradeLevelFilterChange(gradeLevel) {
    this.selectedGradeLevel = gradeLevel;
    console.log('Selected Grade Level:', this.selectedGradeLevel);
    this.getColegio(this.currentPageHorizontal);
  }

  onTestPresentedFilte() {
    this.testPresentedFilter = '';
    if (this.selectedTestPresented['Si']) {
      this.testPresentedFilter = 'Si';
    }
    if (this.selectedTestPresented['No']) {
      this.testPresentedFilter = 'No';
    }
    this.getColegio(this.currentPageHorizontal);
  }
  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getColegio(this.currentPageHorizontal);
  }
  onSortOrderChange() {
    this.sortOrder = '';
    if (this.selectedOrder['Ascendente']) {
      this.sortOrder = 'asc';
    }
    if (this.selectedOrder['Descendente']) {
      this.sortOrder = 'desc';
    }
    this.getColegio(this.currentPageHorizontal);
  }

  onSkillFilterChange(skillId: string) {
    if (this.selectedSkill !== skillId) {
      this.selectedSkill = skillId;
      this.sortBy = this.selectedSkill;
      this.getColegio(this.currentPageHorizontal);
    } else {
      this.selectedSkill = null;
    }
  }

  resetFilters() {
    this.selectedSkill = null;
    this.selectedGrades = {
      '': false,
      '9': false,
      '10': false,
      '11': false,
    };
    this.selectedTestPresented = {
      Si: false,
      No: false,
    };
    this.selectedOrder = {
      Ascendente: false,
      Descendente: false,
    };
    this.selectedGrade = '';
    this.testPresentedFilter = '';
    this.sortBy = '';
    this.sortOrder = '';

    this.getColegio(this.currentPageHorizontal);
  }

  addEllipsisToName(name, maxLength) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    }
    return name;
  }

  isLoading = false;

  async getColegio(page: number) {
    console.log('Fetching data for page:', page);
  
    // Evitar múltiples solicitudes simultáneas
    if (this.isLoading) {
      console.warn('Ya se está procesando una solicitud, por favor espera.');
      return;
    }
  
    this.isLoading = true;
  
    try {
      // Mostrar un spinner mientras se carga la solicitud
      this.ngxSpinnerService.show();
  
      // Realiza la solicitud para obtener datos del colegio
      const response = await this.apiService
        .get({ api: `${this.GET_DATA_SCHOOL}${this.user?._id}` })
        .toPromise();
  
      if (response && response._id) {
        // Asignar datos del colegio
        this.schoolData = response;
        this.nombreColegio = this.schoolData.primerNombre;
  
        // Construir el objeto de consulta
        this.queryStudents = {
          colegio: this.schoolData._id || '',
          page: page || 0,
          limit: this.studentsPerPage || 10,
          searchText: this.searchText || '',
          selectedGrade: this.selectedGrade || '',
          selectedGradeLevel: this.selectedGradeLevel || '',
          testPresentedFilter: this.testPresentedFilter || '',
          sortBy: this.sortBy || 'name',
          sortOrder: this.sortOrder || 'asc',
        };
  
        // Depuración: Imprimir el objeto query
        console.log('Query being sent to getStudentsList:', this.queryStudents);
  
        // Obtener la lista de estudiantes
        await this.getStudentsList(this.queryStudents);
      } else {
        console.warn('La respuesta no contiene un ID válido de colegio:', response);
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se encontraron datos del colegio. Por favor verifica.',
        });
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Algo salió mal en la solicitud, intenta de nuevo más tarde.',
      });
    } finally {
      // Asegurarse de que la bandera y el spinner se liberen al final
      this.isLoading = false;
      this.ngxSpinnerService.hide();
    }
  }
  getStudentsList(queryStudents: object) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.apiService.post({ api: this.GET_STUDENTS, data: queryStudents }).subscribe(
      (resultPaginate) => {
        if (resultPaginate.students && resultPaginate.students.length > 0) {
          this.totalStudents = resultPaginate.totalStudents;
          this.totalPages = Math.ceil(this.totalStudents / this.studentsPerPage);
          this.maxPageHorizontal = Math.floor(this.totalStudents / this.studentsPerPage);
          this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i);

          this.allStudent = resultPaginate.allDataStudents;
          this.students = this.sortBy || this.sortOrder ? resultPaginate.allDataStudents : resultPaginate.students;
          const formatData = [];
          for (const student of this.students) {
            if (Array.isArray(this.linkName) && this.linkName.length === 1 && this.linkName[0] === null) {
              this.linkName = [];
            } else {
              this.linkName = student.linkHv;
            }

            let link = '';
            if (student.linkHv != null && student.linkHv.length > 0) {
              link = `${window.location.origin}/web/viewProfile/${this.linkName}`;
            }

            const englishLanguage = student.englishRange[0];
            const profilePhoto = student.documents[0]?.filter((document) => document.nombre == 'photo');
            const skillsPoints = student.skillsScore[0] || {};
            this.generalScore =
              skillsPoints.Liderazgo +
              skillsPoints.GlobalMind +
              skillsPoints.ImpactoResponsabilidad +
              skillsPoints.OrientacionResultados +
              skillsPoints.CreatividadInovacion +
              skillsPoints.AprendizajePermanente;
            const skillsAverage = parseFloat((this.generalScore / 6).toFixed(2));

            const data = {
              userId: student.userId[0],
              email: student.email,
              name: `${student.userName[0]} ${student.lastnameUser[0]}`,
              grade: student.schoolGrade,
              formatedGrade: student.schoolGrade ? student.schoolGrade : 'Grado no definido',
              gradeLevel: student.schoolGradeLevel ? student.schoolGradeLevel : 'Nivel de grado no definido',
              promedioGeneral: skillsAverage ? skillsAverage : 0,
              Liderazgo: student.skillsScore[0]?.Liderazgo || 0,
              OrientacionResultados: student.skillsScore[0]?.OrientacionResultados || 0,
              AprendizajePermanente: student.skillsScore[0]?.AprendizajePermanente || 0,
              ImpactoResponsabilidad: student.skillsScore[0]?.ImpactoResponsabilidad || 0,
              GlobalMind: student.skillsScore[0]?.GlobalMind || 0,
              CreatividadInovacion: student.skillsScore[0]?.CreatividadInovacion || 0,
              InteligenciaEmocional: student.skillsScore[0]?.InteligenciaEmocional || 0,
              englishLevel: englishLanguage || 'Sin nivel',
              fulltest: student.fullTest,
              linkHv: link,
              linkName: this.linkName,
              presentTest: student.test.length > 0 ? 'Si' : 'No',
              profilePicture: profilePhoto?.length > 0 ? profilePhoto[0].archivo : '/assets/sidebar/profile.png',
            };

            formatData.push(data);
          }
          this.listTable = formatData;
          this.studentsData = new MatTableDataSource(formatData);
          this.ngxSpinnerService.hide();
          Swal.close();
          if (formatData.length > 0) {
            this.studentData = formatData;
            this.filteredStudents = this.studentData;
            this.filteredStudents = this.studentData.slice(0, this.studentsPerPage);
          }
        }
      },
      (error) => {
        console.error('Error en la solicitud:', error);
        this.ngxSpinnerService.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Algo salio en la solicitud, intenta de nuevo más tarde ',
        });
      }
    );
  }

  isNextButtonDisabled() {
    return this.currentPageHorizontal >= this.maxPageHorizontal;
  }

  calculateStartResult(): number {
    if (this.totalStudents === 0) {
      return 0;
    }
    const start = this.currentPageHorizontal * this.studentsPerPage + 1;
    return start;
  }

  calculateEndResult(): number {
    if (this.totalStudents === 0) {
      return 0;
    }
    const start = this.currentPageHorizontal * this.studentsPerPage + 1;
    let end = start + this.studentsPerPage - 1;
    if (end > this.totalStudents) {
      end = this.totalStudents;
    }

    return end;
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', (event) => this.closeFiltersOnOutsideClick(event));
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    if (this.showFilters) {
      this.showFilterSort = false;
      this.showFilterSortUp = false;
    }
  }

  closeFiltersOnOutsideClick(event: MouseEvent): void {
    const filterMenu = document.querySelector('.filter-menu');

    if (filterMenu && !filterMenu.contains(event.target as Node)) {
      this.showFilters = false;
      this.showFilterSort = false;
      this.showFilterSortUp = false;
    }
  }

  toggleFiltersSort() {
    this.showFilterSort = !this.showFilterSort;
    if (this.showFilterSort) {
      this.showFilters = false;
      this.showFilterSortUp = false;
    }
  }

  getByPagination(event) {
    const itemsPerPage = event.pageSize;
    const pageIndex = event.pageIndex;
    this.currentPageHorizontal = pageIndex * (this.studentsPerPage / itemsPerPage);
  }
  navigateToResume(event) {
    this.navigationService.navigateTo({
      path: 'student-resume/' + event,
      params: event,
    });
  }
}
