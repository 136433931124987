import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-school-comparison-dashboard',
  templateUrl: './school-comparison-dashboard.component.html',
  styleUrls: ['./school-comparison-dashboard.component.scss'],
})
export class SchoolComparisonDashboard implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  loggedSchool: any;
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly GET_STUDENTS = 'api/skillTestByColegio/student-list';
  readonly GET_SCHOOLS = 'api/colegio';
  readonly GET_CLUSTERED_SCHOOLS = 'api/clusteredSchools';
  public chartOptions: any;
  public averageChartoptions: any;
  public chartProgrammsOptions: any;
  public testChart: any;
  public chartLanguagesOptions: any;
  public skillsLevelChart: any;
  languagesChart: any;
  studentsPerPage = 25;
  searchText: string = '';
  testPresentedFilter: string = '';
  nombreColegio: '';
  dataSource: any;
  studentData: any;
  studentPerPage: any;
  pageLimit: any;
  students: any[] = [];
  queryStudents: any = {};
  aprendizajePermanenteLevel: any;
  globalMindLevel: any;
  inteligenciaEmocionalLevel: any;
  creatividadInnovacionLevel: any;
  impactoResponsabilidadLevel: any;
  liderazgoLevel: any;
  orientacionResultadosLevel: any;
  averageLevel: any;
  selectedGrade: string = '';
  schoolData: any;
  studentsData: any;
  schoolName = '';
  sortBy: string = '';
  sortOrder: string = '';
  totalStudents: any;
  generalScore: any;
  sizePagination = [];
  skills: any;
  studentFilter: any;
  listTable: any = [];
  seriesLevel: any;
  skillAverages: any;
  selectedChartData: any;
  currentPageHorizontal: any;
  skillsChartOptions: any;
  englishlLevelChartOptions: any;
  skillsLevelsChartOptions: any;
  selectedSkill: any;
  selectedLevelsSkill: any;
  skillsChartsOptions: any;
  schoolGroups: any;
  filteredSchoolGroups: any;
  selectedOption: any;
  groupedSchool: any[] = [];
  selectedFilterValue: any;
  skillsOptions = [
    { value: 'OverallAverage', label: 'Promedio General' },
    { value: 'AprendizajePermanente', label: 'Aprendizaje Permanente' },
    { value: 'CreatividadInovacion', label: 'Creatividad e Innovación' },
    { value: 'GlobalMind', label: 'Global Mind' },
    { value: 'ImpactoResponsabilidad', label: 'Impacto y Responsabilidad' },
    { value: 'Liderazgo', label: 'Liderazgo' },
    { value: 'OrientacionResultados', label: 'Orientación a Resultados' },
  ];
  options = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Colegios IB', label: 'Bilingue' },
    { value: 'Colegios NoIB', label: 'No bilingue' },
    { value: 'Quindío', label: 'Region' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Colegios IB Quindío', label: 'Bilingues de la misma region' },
    { value: 'Colegios calendario B', label: 'Calendario b' },
  ];
  schoolsData: any;
  allStudentData: any;
  themePalette = ['#4ccdb2', '#fab902', '#775dd0', '#3dc8e1', '#ea447c', '#2b96f3', '#Ee8c2c', '#Bbda86'];
  resultPaginate: any;
  isBilingual: boolean = true;
  querySchools: any;
  selectedTypeOfSchool: any;
  selectedCalendarType: any;
  selectedClassificationByGender: any;
  selectedIsBilingual: any;
  filteredSchools: any;
  studentTest: any;
  selectedSkillToFilter: string = '';
  averagesSkillsBySchool: any;
  clusteredSchools: any;
  groupedSkillCounts: any;
  selectedGroup: any;
  filteredSchoolGroupsNames: any;
  schoolGroups2: any;
  selectedSchools: any;
  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
   // this.loggedSchool = this.user.nombre;
   // this.currentPageHorizontal = 0;
   // this.selectedOption = 'Todos';
   // this.getColegio(this.currentPageHorizontal);
   // this.getClusteredSchools();
   // this.selectedSkill = 'OverallAverage';
   // this.selectedLevelsSkill = 'Liderazgo';
   // this.applyFilter();
   // this.languageSkill();
   // this.filterBySkill();
   // this.filterCategoriesSkill();
  }

  ngOnDestroy(): void {}

  getClusteredSchools() {
    this.ngxSpinnerService.show();
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const loggedUserId = this.user._id;
    this.apiService.get({ api: `${this.GET_CLUSTERED_SCHOOLS}?loggedUserId=${loggedUserId}` }).subscribe(
      (response) => {
        this.clusteredSchools = response;
        this.schoolGroups = Object.keys(response.groupedSchools);
        this.applyFilter();
        this.languageSkill();
        this.filterBySkill();
        this.filterCategoriesSkill();
        this.ngxSpinnerService.hide();
        Swal.close();
      },
      (error) => {
        console.error('Error en el get:', error);
        this.ngxSpinnerService.hide();
        Swal.fire({
          icon: 'error',
          title: '¡Vaya!',
          text: 'Se produjo un error. Por favor, inténtelo de nuevo más tarde.',
        });
      }
    );
  }

  applyFilter() {
    if (this.selectedOption === 'Todos') {
      this.selectedGroup = null;
      this.selectedSchools = {};
      this.filteredSchoolGroups = this.clusteredSchools;
    } else if (this.selectedOption) {
      this.selectedGroup = this.selectedOption;
      this.selectedSchools = this.clusteredSchools.groupedSchools[this.selectedGroup];
      this.filteredSchoolGroups = {
        groupedSchools: {
          [this.loggedSchool]: this.clusteredSchools.groupedSchools[this.loggedSchool],
          [this.selectedGroup]: this.selectedSchools,
        },
      };
    } else {
      this.selectedGroup = null;
      this.selectedSchools = {};
      this.filteredSchoolGroups = {};
    }
    this.filteredSchoolGroupsNames = Object.keys(this.filteredSchoolGroups.groupedSchools);
    this.languageSkill();
    this.filterBySkill();
    this.filterCategoriesSkill();
  }

  filterBySkill() {
    const skillAverages = {};
    this.schoolGroups.forEach((group) => {
      const schools = this.clusteredSchools.groupedSchools[group];
      const skillTotals = {
        AprendizajePermanente: 0,
        CreatividadInovacion: 0,
        GlobalMind: 0,
        ImpactoResponsabilidad: 0,
        Liderazgo: 0,
        OrientacionResultados: 0,
        OverallAverage: 0,
      };

      schools.forEach((school) => {
        for (const skill in skillTotals) {
          const skillPoints = school.averageSkillPoints;
          if (skillPoints && skillPoints[skill] !== null) {
            skillTotals[skill] += skillPoints[skill];
          }
        }
      });

      const skillAveragesGroup = {};
      for (const skill in skillTotals) {
        const length = schools.length;
        skillAveragesGroup[skill] = length > 0 ? (skillTotals[skill] / length).toFixed(2) : '0.00';
      }

      skillAverages[group] = skillAveragesGroup;
    });

    const skillToDisplay = this.selectedSkill;
    const dataSeries = this.filteredSchoolGroupsNames.map(
      (group) => `${parseFloat(skillAverages[group][skillToDisplay]).toFixed(2)}%`
    );

    this.skillsChartsOptions = {
      series: [
        {
          name: 'Porcentaje del grupo ',
          data: dataSeries,
        },
      ],
      theme: {
        palette: 'palette1',
      },
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {},
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          distributed: true,
        },
      },
      stroke: {
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 0.8,
      },
      yaxis: {
        labels: {
          formatter: (y) => `${y.toFixed(0)}%`,
        },
      },
      xaxis: {
        categories: this.filteredSchoolGroupsNames,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }

  filterCategoriesSkill() {
    const groupedSkillCounts = {};

    const initializeSkillCounts = () => ({
      Master: 0,
      Professional: 0,
      Higher: 0,
      Average: 0,
      Low: 0,
    });

    for (const groupName in this.filteredSchoolGroups.groupedSchools) {
      if (this.filteredSchoolGroups.groupedSchools.hasOwnProperty(groupName)) {
        const schoolsInGroup = this.filteredSchoolGroups.groupedSchools[groupName];
        const groupSkillCounts = {};

        for (const school of schoolsInGroup) {
          for (const skillName in school.skillCounts) {
            if (school.skillCounts.hasOwnProperty(skillName) && skillName === this.selectedLevelsSkill) {
              const skillCount = school.skillCounts[skillName];
              if (!groupSkillCounts[skillName]) {
                groupSkillCounts[skillName] = initializeSkillCounts();
              }

              for (const level in skillCount) {
                if (skillCount.hasOwnProperty(level)) {
                  groupSkillCounts[skillName][level] += skillCount[level];
                }
              }
            }
          }
        }

        groupedSkillCounts[groupName] = groupSkillCounts;
      }
    }

    const categories = Object.keys(groupedSkillCounts);
    const series = [];
    const levels = Object.keys(groupedSkillCounts[categories[0]][this.selectedLevelsSkill]);

    for (const level of levels) {
      const levelData = {
        name: level,
        data: [],
      };

      for (const categoryName of categories) {
        const skillCounts = groupedSkillCounts[categoryName][this.selectedLevelsSkill];
        levelData.data.push(skillCounts?.[level] || 0);
      }
      series.push(levelData);
    }

    this.skillsLevelsChartOptions = {
      series: series,
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '11px',
          fontWeight: 500,
        },
      },
      theme: {
        palette: 'palette1',
      },
      colors: this.themePalette,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30px',
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      legend: {
        position: 'top',
        offsetY: 0,
      },
      stroke: {
        width: 3,
      },
      fill: {
        opacity: 0.8,
      },
    };
  }

  languageSkill() {
    const englishLevelCount = {
      A1: 0,
      A2: 0,
      B1: 0,
      B2: 0,
      C1: 0,
      C2: 0,
    };

    const categories = Object.keys(this.filteredSchoolGroups.groupedSchools);

    const countEnglishLevels = (englishLevelCount, schoolsInGroup) => {
      for (const level in englishLevelCount) {
        englishLevelCount[level] = 0;
      }

      schoolsInGroup.forEach((school) => {
        const students = school.students;

        students.languageLevel.forEach((student) => {
          if (student.languageLevel && student.languageLevel in englishLevelCount) {
            englishLevelCount[student.languageLevel]++;
          }
        });
      });
    };

    const processCategories = (categories, filteredSchoolGroups, englishLevelCount) => {
      for (const categoryName of categories) {
        const schoolsInGroup = filteredSchoolGroups.groupedSchools[categoryName];
        countEnglishLevels(englishLevelCount, schoolsInGroup);
      }
    };

    processCategories(categories, this.filteredSchoolGroups, englishLevelCount);

    const calculateLevelCount = (schoolsInGroup, level) => {
      let levelCount = 0;
      for (const school of schoolsInGroup) {
        const students = school.students;
        students.languageLevel.forEach((student) => {
          if (student.languageLevel === level) {
            levelCount++;
          }
        });
      }
      return levelCount;
    };

    const generateSeriesData = (englishLevelCount, categories, filteredSchoolGroups) => {
      return Object.keys(englishLevelCount).map((level) => ({
        name: level,
        data: categories.map((categoryName) => {
          const schoolsInGroup = filteredSchoolGroups.groupedSchools[categoryName];
          return calculateLevelCount(schoolsInGroup, level);
        }),
      }));
    };

    const seriesData = generateSeriesData(englishLevelCount, categories, this.filteredSchoolGroups);

    this.languagesChart = {
      series: seriesData,
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '11px',
          fontWeight: 500,
        },
      },
      theme: {
        palette: 'palette1',
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30px',
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      legend: {
        position: 'top',
        offsetY: 0,
      },
      stroke: {
        width: 3,
      },
      fill: {
        opacity: 0.8,
      },
    };
  }

  async getColegio(page: number) {
    try {
      const response = await this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).toPromise();

      if (response._id) {
        this.schoolData = response;
        this.nombreColegio = this.schoolData.nombre;

        this.queryStudents = {
          colegio: this.schoolData._id,
          page: page,
          limit: this.studentsPerPage,
          searchText: this.searchText,
          selectedGrade: this.selectedGrade,
          testPresentedFilter: this.testPresentedFilter,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        };
        this.getStudentsList(this.queryStudents);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      Swal.fire({
        icon: 'error',
        title: '¡Vaya!',
        text: 'Se produjo un error. Por favor, inténtelo de nuevo más tarde.',
      });
    } finally {
      this.ngxSpinnerService.hide();
    }
  }

  getStudentsList(queryStudents: object) {
    this.apiService.post({ api: this.GET_STUDENTS, data: queryStudents }).subscribe(
      (resultPaginate) => {
        this.allStudentData = resultPaginate.allUserData;
        this.resultPaginate = resultPaginate.allStudentsInfo;
        if (resultPaginate.allStudentsInfo && resultPaginate.allStudentsInfo.length > 0) {
          this.students = this.resultPaginate;
          const formatData = [];
          this.listTable = formatData;
          this.studentsData = new MatTableDataSource(formatData);
          this.ngxSpinnerService.hide();
        }
      },
      () => this.ngxSpinnerService.hide()
    );
  }
}
